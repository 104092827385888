import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthContext } from "../Contexts/AuthContext";
import { Link, Navigate } from "react-router-dom";

const CryptoOrders = () => {
    const { userdata } = useAuthContext();
    const [orders, setOrders] = useState([]);
    const [show, setShow] = useState(false);
    const [order, setOrder] = useState({});

    const FetchOrders = async () => {
        try {
            const { data } = await axios.get(
                process.env.REACT_APP_BASE_URL + "/api/orders/cryptoorders",
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: Cookies.get("token"),
                    },
                }
            );
            setOrders(data.orders);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        FetchOrders();
    }, []);

    const Open = (orderData) => {
        setOrder(orderData);
        setShow(true);
    };

    const CheckColor = (elem) => {
        let color = "";
        if (
            elem.toUpperCase() === "PENDIENTE" ||
            elem.toUpperCase() === "RECOGIENDO" ||
            elem.toUpperCase() === "ANULADA"
        ) {
            color = "red";
        } else if (elem.toUpperCase() === "ADUANAS") {
            color = "yellow";
        } else if (
            elem.toUpperCase() === "ENTREGADO" ||
            elem.toUpperCase() === "DISPONIBLE" ||
            elem.toUpperCase() === "COMPLETADA"
        ) {
            color = "green";
        } else {
            color = "blue";
        }

        return color;
    };


    if (!userdata) return <Navigate to="/" />;

    return (
        <>
            <PageBanner title="Historial de Walletven" />
            <div className="Orderhistory mt-10 table-responsive canvas">
                <table className="table bg-white rounded shadow">
                    <thead className="text-white bg-dark">
                        <tr>
                            <th>Orden</th>
                            {window.matchMedia("(min-width: 650px)").matches &&
                                <>
                                    <th>Fecha</th>
                                    <th>Beneficiario</th>
                                </>
                            }
                            <th>Estado</th>
                            <th>Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                            <tr key={order.orderId}>
                                <td>WV{order.orderId}</td>
                                {window.matchMedia("(min-width: 650px)").matches &&
                                    <>
                                        <td>{order.date}</td>
                                        <td>{order.beneficiary}</td>
                                      
                                    </>
                                }
                                  <td>
                                            <p
                                                className={`badge rounded-pill py-2 px-3 ${CheckColor(
                                                    order.status
                                                )}`}
                                            >
                                                {order.status.toUpperCase()}
                                            </p>
                                        </td>
                                <td>
                                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#orderModal" onClick={() => Open(order)}>
                                        Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Bootstrap Modal */}
                {show &&
                <div className="modal_bg d-flex justify-content-center 
          align-items-center">
                    <div className={`modal fade ${show ? "show d-flex" : ""}`} id="orderModal" tabIndex="-1" aria-hidden={!show}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title "> Detalles #<span className="notranslate">WV{order.orderId}</span></h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setShow(false)}></button>
                                </div>
                                <div className="modal-body d-flex flex-wrap">
                                    <div className="col-md-6 col-12">
                                        <p>Beneficiario: {userdata.name} {userdata.surname}</p>
                                        <p>CriptoMoneda: {order.name}</p>
                                        <p>Total a pagar: {order.total}</p>
                                        <p>Recibe: {order.gets}</p>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <p>Email: {userdata.email}</p>
                                        <p>Dirección: {order.direction}</p>
                                        <p>Red: {order.red}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                }
            </div>
        </>
    );
};

export default CryptoOrders;
